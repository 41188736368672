@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital@0;1&display=swap);
@font-face {
  font-family: "Bubble";
  src: url(/static/media/Bubblegum.b60ab23c.ttf);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #feffe8;
}


.wrapper {
  text-align: center;
  font-family: "Bubble", "Roboto Condensed", sans-serif;
  color: black;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background: #feffe8;
}

audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  display: none;
  width: auto;
}

audio::-webkit-media-controls-mute-button {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

.bg-container {
  background-color: #49acd7;
  object-fit: cover;
  /* height: 100vh; */
  /* width: 100%; */
  margin-top: 0;
  margin-left: 0;
}

.box-img {
  margin-top: 150px;
}
body {
  margin: 0;
  font-family: motor,monospace;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "AloneInSpace", sans-serif;
}

